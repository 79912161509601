import { createContext, useState } from "react";

export const GlobalStateFooterContext = createContext();

const GlobalFooterProvider = ({children}) => {
    const [isDynamic, setIsDynamic] = useState(false);
    const [tenantFooter, setTenantFooter] = useState("");
    
    return <GlobalStateFooterContext.Provider value={{ isDynamic, setIsDynamic, tenantFooter, setTenantFooter }} >
        { children }
    </GlobalStateFooterContext.Provider>
}

export default GlobalFooterProvider;
