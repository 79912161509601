import { css } from '@emotion/react' 
import { useTheme } from '@findep/microfronts-core'

export default function MainSection({children, gradient}) {
    const { palette } = useTheme()
    const background = gradient ? `background: linear-gradient(white, ${palette.layout?.background || 'white'});` : 'background-color: white;'
    const root = css`
        height: 100%;
        width: 100vw;
        overflow-x: hidden;
        overflow-y: auto;
        ${background}
    `
    return (
        <main css={root}>
            {children}
        </main>
    )
}
