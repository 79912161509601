import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from '@material-ui/core'

import {
  Footer
} from '@findep/mf-landings-core'
import { GlobalStateFooterContext } from '../../context/GlobalStateFooterContext';

import AefLogo from '../../assets/altoperfil/aef/logo.png'
import FisaLogo from '../../assets/altoperfil/fisa/logo.png'


function FooterFindep({ broker, companyName, data }) {

  const { isDynamic, tenantFooter } = useContext(GlobalStateFooterContext)

  const tenantImage = <img src={tenantFooter === "AEF" ? AefLogo : FisaLogo} alt="" width={tenantFooter === "AEF" ? '80' : '110'} height="80" />

  let matches = useMediaQuery('(min-width:768px)');
  let footer = []
  if ((broker !== undefined && broker !== null)) {
    footer = data.allComponentsJson.edges[0].node.footer.find(item => (item.company === companyName && item.broker === broker))
    if (footer === undefined) {
      footer = data.allComponentsJson.edges[0].node.footer.find(item => item.company === companyName)
    }
  } else {
    footer = data.allComponentsJson.edges[0].node.footer.find(item => item.company === companyName)
  }

  let ca = []
  footer.condusefarray.map((row) => {
    return ca.push({ imagen: (<img src={row.src.publicURL} alt="" width="91" height="48" />), texto: row.texto, link: row.link })
  })
  let it = []
  
  footer.imagestext.map((row) => {
    let urlFaq = "";
    if(companyName === "CREDIALTO") {
      urlFaq = tenantFooter === "AEF" ? "https://home.apoyoeconomico.com.mx/credialto/" : "https://home.independencia.com.mx/credialto/"
    } else {
      urlFaq = tenantFooter === "AEF" ? "https://home.apoyoeconomico.com.mx/credito-formal-digital/ " : "https://home.independencia.com.mx/credito-formal-digital/"
    }
    urlFaq = isDynamic && row.texto.includes("FAQ") ? urlFaq : row.link
    return it.push({ imagen: (<img src={row.src.publicURL} alt={row.texto} width="20" height="20" />), texto: row.texto, extra: row.extra, link: urlFaq  })
  })

  

  let ba = []
  footer.buroarray.map((row) => {
    return ba.push({ imagen: (<img src={row.src.publicURL} alt="" width="80" height="80" />), texto: row.texto, extra: row.extra, link: row.link })
  })

  let linkTenant = tenantFooter === "AEF" ? footer.linkone : footer.linktwo
  let leyendas = footer.leyendas
  let linkone = footer.linkone
  let linktree = footer.linktree
  let linktwo = footer.linktwo
  let terminosyavisos = footer.terminosyavisos
  let terminosyavisosafileft = footer.terminosyavisosafileft
  let terminosyavisosafiright = footer.terminosyavisosafiright
  
  let otr = []
  footer.otros.map((row) => {
    return otr.push({ imagen: (<img src={row.src.publicURL} alt="" width="80" height="80" />), link: row.link, texto: row.texto })
  })

  
  const buroAef = [otr[0]]
  const buroFisa = [otr[1]]

  const buroDinamico = tenantFooter === 'AEF' ? buroAef : buroFisa

  const buroArray = isDynamic ? buroDinamico : ba

  const companiesFooter = {
    'AEFALTOPERFIL': true,
    'CREDIALTO': true,
    'KAHOOLCREDIT': true,
    'TECNOCREDITO': true,
  }
  
  const customizedFooter = companiesFooter[companyName]

  return (
    <>
      <div style={{ marginBottom: matches ? '60px' : '20px' }} />
      {companyName === "AFI"
        ?
        <Footer afi terminosyavisosafiright={terminosyavisosafiright} terminosyavisosafileft={terminosyavisosafileft} fontsectionone={{ family: 'Roboto-Condensed', size: '14px', redesSize: '17px' }} linkone={linkone} linktwo={linktwo} linktree={linktree} leyendas={leyendas} colorfooter="#eeedf0" condusefarray={ca} buroarray={ba} otros={otr} imagestext={it} />
        :
        <Footer genericFooter={!isDynamic} dynamicFooter={isDynamic} companyImage={tenantImage} customizedFooter={customizedFooter} terminosyavisos={terminosyavisos} fontsectionone={{ family: 'Roboto-Condensed', size: '14px', redesSize: '17px' }} linkone={isDynamic ? linkTenant : []} linktwo={[]} linktree={linktree} leyendas={leyendas} colorfooter="#eeedf0" condusefarray={ca} buroarray={buroArray} otros={otr} imagesTextSecondSection={it} />
      }
    </>
  )
}

FooterFindep.propTypes = {
  companyName: PropTypes.oneOf(['AEF', 'AFI', 'FISA']),
  data: PropTypes.object.isRequired
}

export default FooterFindep

